import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppNoResultComponent } from './app-no-result/app-no-result.component';


const COMPONENTS = [
    AppNoResultComponent
];
@NgModule({
	declarations: [
        ...COMPONENTS
    ],
	imports: [
        IonicModule,
        CommonModule,
    ],
	exports: [
        ...COMPONENTS
    ]
})
export class ComponentsModule {}
