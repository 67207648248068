import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-result',
  templateUrl: './app-no-result.component.html',
  styleUrls: ['./app-no-result.component.scss']
})
export class AppNoResultComponent implements OnInit {
  @Input()img: string = "assets/imgs/no-detail.png";
  @Input()text: string = "暂无信息";
  propagateChange: any = {};
  isShow: boolean = true;
  constructor() { }
  /*实现ControlValueAccessor接口部分*/
  writeValue(val: Array<any>): void {
    if (val) {
      if(val.length > 0) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    } else {
      this.isShow = true;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  ngOnInit() {
  }

}
